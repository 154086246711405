
import { defineComponent, onMounted, ref, Ref, watch } from 'vue'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import { JobBankRouteNames } from '@/router/route-names'
import jobOfferCard from '@/views/jobBank/children/components/JobOfferCard.vue'
import { IJobOfferCard } from '@/models/jobBank/interfaces/jobOffer'
import { JobOffersAPI } from '@/api/job-offers.api'
import { useToast } from 'primevue/usetoast'
import { formatJobOfferCard, formatJobOfferList } from '../helpers/formatJobOfferCard'
import DataTable from 'primevue/datatable'

export default defineComponent({

  components: {
    Button,
    InputText,
    jobOfferCard,
    DataTable
  },
  setup () {
    const filter = ref(['published'])
    const toggleAllFilters = () => {
      if (filter.value.includes('all') && filter.value.length < 4) {
        filter.value = ['all', 'published', 'notPublished', 'finished']
      } else if (!filter.value.includes('all') && filter.value.length < 4) {
        filter.value = []
      }
    }
    const search = ref('')
    const clear = () => {
      filter.value = []
    }
    const showImage = ref(true)
    const loading = ref(false)
    const jobOffers: Ref<IJobOfferCard[]> = ref([])
    const jobOffersList : Ref<any[]> = ref([])
    const allJobOffers : Ref<any[]> = ref([])
    const jobOffersRaw : Ref<any[]> = ref([])
    const jobOffersTable = ref()
    const scrollComponent = ref(null)
    let currentPage = 1
    const limitPage = 8
    let totalJobOffers = 0
    let timeOutID: number

    watch([filter, search], () => {
      if (timeOutID) clearTimeout(timeOutID)
      loading.value = true
      currentPage = 1
      jobOffers.value = []
      timeOutID = setTimeout(async () => {
        loadJobOffers(true)
      }, 1000)
    })

    const createExcel = () => {
      jobOffersTable.value.exportCSV()
    }
    const loadJobOffers = async (shouldReset?: boolean) => {
      loading.value = true
      try {
        const { data: apiResponse, count } = await JobOffersAPI.findAll(currentPage, limitPage, search.value, filter.value.join('|'))
        jobOffersRaw.value = apiResponse
        totalJobOffers = count
        if (shouldReset) jobOffers.value = []
        jobOffers.value.push(...formatJobOfferCard(apiResponse))
        loading.value = false
      } catch (e) {
        loading.value = false
        jobOffers.value = []
      }
    }

    const handleScroll = () => {
      if (window.scrollY + innerHeight >= document.body.scrollHeight - 1) {
        if (currentPage <= totalJobOffers / limitPage) {
          currentPage++
          loadJobOffers()
        }
      }
    }

    const unPublishCard = ref()

    const displayModal = ref(false)
    const openModal = () => {
      displayModal.value = true
    }

    const closeModal = () => {
      displayModal.value = false
    }
    const handleStatusChange = (event: any) => {
      openModal()
      const cardId = event?.card_id
      unPublishCard.value = jobOffersRaw.value.find(jobOfferCard => jobOfferCard._id === cardId)
    }

    const toast = useToast()

    const unPublish = () => {
      unPublishCard.value.publishDate = null
      unPublishCard.value.endDate = null
      JobOffersAPI.updateJobOffer(unPublishCard.value, unPublishCard.value._id)
      closeModal()
      toast.add({ severity: 'success', detail: '¡Listo! Se dejó de publicar la oferta laboral.', life: 3500 })
      jobOffers.value = []
      loadJobOffers()
    }

    onMounted(async () => {
      window.addEventListener('scroll', handleScroll)
      loadJobOffers()
      allJobOffers.value = await JobOffersAPI.findAllJobOffers()
      jobOffersList.value.push(...formatJobOfferList(allJobOffers.value))
    })

    return {
      filter,
      search,
      clear,
      JobBankRouteNames,
      jobOffers,
      loading,
      scrollComponent,
      handleStatusChange,
      openModal,
      closeModal,
      displayModal,
      unPublish,
      totalJobOffers,
      showImage,
      jobOffersRaw,
      jobOffersList,
      jobOffersTable,
      createExcel,
      toggleAllFilters
    }
  }
})
